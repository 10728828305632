import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop ';
const HomeOne = lazy(() => import("./home/HomeOne"));
// eslint-disable-next-line
{
  /* const HomeTwo = lazy(() => import("./home/HomeTwo"));
const HomeThree = lazy(() => import("./home/HomeThree"));
*/
}
const Produits = lazy(() => import("./produit/Produits"));
const MagneticEquipementDetailsLeftSidebar = lazy(() =>
  import("./produit/MagneticEquipementDetailsLeftSidebar")
);
const OpticalSortingEquipementDetailsLeftSidebar = lazy(() =>
  import("./produit/OpticalSortingEquipementDetailsLeftSidebar")
);
const TurnkeySystemsDetailsLeftSidebar = lazy(() =>
  import("./produit/TurnkeySystemsDetailsLeftSidebar")
);
const SparePartsDetailsLeftSidebar = lazy(() =>
  import("./produit/SparePartsDetailsLeftSidebar")
);
const ConveyorsDetailsLeftSidebar = lazy(() =>
  import("./produit/ConveyorsDetailsLeftSidebar")
);
const FiberScreensDetailsLeftSidebar = lazy(() =>
  import("./produit/FiberScreensDetailsLeftSidebar")
);
const OtherOEMProductsDetailsLeftSidebar = lazy(() =>
  import("./produit/OtherOEMProductsDetailsLeftSidebar")
);
const RagnartechProductsDetailsLeftSidebar = lazy(() =>
  import("./produit/RagnartechProductsDetailsLeftSidebar")
);

const About = lazy(() => import("./pages/AboutUs"));
const ServiceDetailsLeftSidebar = lazy(() =>
  import("./service/ServiceDetailsLeftSidebar")
);
const ServiceDetailsRightSidebar = lazy(() =>
  import("./service/ServiceDetailsRightSidebar")
);
//modifcation par naz 
const Conveyors_Apron_Produits  = lazy(() =>
  import("./produit/Conveyors_Apron_Produits")
);

const Conveyors_IDLER_Produits  = lazy(() =>
  import("./produit/Conveyors_IDLER_Produits")
);
const Conveyors_SLIDER_Produits  = lazy(() =>
  import("./produit/Conveyors_SLIDER_Produits")
);

const Conveyors_combo_belt_Produits   = lazy(() =>
  import("./produit/Conveyors_combo_belt_Produits")
);

const Turnkey_Systems_SS  = lazy(() =>
  import("./produit/Turnkey_Systems_SS")
);
const Turnkey_Systems_CandD  = lazy(() =>
  import("./produit/Turnkey_Systems_CandD")
);

const Turnkey_Systems_MSW  = lazy(() =>
  import("./produit/Turnkey_Systems_MSW")
);
const Turnkey_Systems_Mulch  = lazy(() =>
  import("./produit/Turnkey_Systems_Mulch")
);
const Conveyors_Auger_Produits = lazy(() =>
  import("./produit/Conveyors_Auger_Produits")
);
const OtherOEMProducts_Trommel = lazy(() =>
  import("./produit/OtherOEMProducts_Trommel")
);
const OtherOEMProducts_BUNKER = lazy(() =>
  import("./produit/OtherOEMProducts_BUNKER")
);
const RagnartechProducts_Chippers= lazy(() =>
  import("./produit/RagnartechProducts_Chippers")
);
const RagnartechProducts_CraneAttachments= lazy(() =>
  import("./produit/RagnartechProducts_CraneAttachments")
);
const RagnartechProducts_GrindersAndShredders= lazy(() =>
  import("./produit/RagnartechProducts_GrindersAndShredders")
);

const Services= lazy(() =>
  import("./service/Services")
);
const Projects= lazy(() =>
  import("./project/Projects")
  
);
const MagneticEquipment= lazy(() =>
  import("./produit/MagneticEquipment")
  
);


// eslint-disable-next-line
{
  /*const Projects = lazy(() => import("./project/Projects"));
const ProjectDetails = lazy(() => import("./project/ProjectDetails"));*/
}
// eslint-disable-next-line
const BlogLeftSidebar = lazy(() => import("./blog/BlogLeftSidebar"));
// eslint-disable-next-line
const BlogRightSidebar = lazy(() => import("./blog/BlogRightSidebar"));
// eslint-disable-next-line
const BlogDetailsLeftSidebar = lazy(() =>
  import("./blog/BlogDetailsLeftSidebar")
);
// eslint-disable-next-line
const BlogDetailsRightSidebar = lazy(() =>
  import("./blog/BlogDetailsRightSidebar")
);
const Contact = lazy(() => import("./pages/Contact"));
const NoMAtch = lazy(() => import("./pages/404"));

const Talking_About_Us= lazy(() =>
  import("./Talking_About_Us/Talking_About_Us")
  
);




const App = () => {
  return (
    <Router>
      <Suspense fallback={<div />}>
      <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomeOne />} />
          <Route path="/home" element={<HomeOne />} />
          {/*<Route path="/home-two" element={<HomeTwo />} />*/}
          {/*<Route path="/home-three" element={<HomeThree />} />*/}
          <Route path="/about-us" element={<About />} />
          <Route path="/products" element={<Produits />} />
          {/*<Route path="/services" element={<Services />} />*/}
          <Route
            path="/service-details-left-sidebar"
            element={<ServiceDetailsLeftSidebar />}
          />
          <Route
            path="/magnetic-equipement-details-left-sidebar"
            element={<MagneticEquipementDetailsLeftSidebar />}
          />
          <Route
            path="/optical-sorting-equipement-left-sidebar"
            element={<OpticalSortingEquipementDetailsLeftSidebar />}
          />
          <Route
            path="/turnkey-systems-left-sidebar"
            element={<TurnkeySystemsDetailsLeftSidebar />}
          />
          <Route
            path="spare-parts-left-sidebar"
            element={<SparePartsDetailsLeftSidebar />}
          />
          <Route
            path="conveyors-left-sidebar"
            element={<ConveyorsDetailsLeftSidebar />}
          />
          <Route
            path="fiber-screens-left-sidebar"
            element={<FiberScreensDetailsLeftSidebar />}
          />
          <Route
            path="other-OEM-products-left-sidebar"
            element={<OtherOEMProductsDetailsLeftSidebar />}
          />
          <Route
            path="ragnartech-products-left-sidebar"
            element={<RagnartechProductsDetailsLeftSidebar />}
          />

          <Route
            path="/service-details-right-sidebar"
            element={<ServiceDetailsRightSidebar />}
          />
          {/*<Route path="/projects" element={<Projects />} />
          <Route path="/project-details" element={<ProjectDetails />} />*/}
          {/*<Route path="/blog-left-sidebar" element={<BlogLeftSidebar />} />
          <Route path="/blog-right-sidebar" element={<BlogRightSidebar />} />*/}
          {/*<Route
            path="/blog-details-left-sidebar"
            element={<BlogDetailsLeftSidebar />}
          />
          <Route
            path="/blog-details-right-sidebar"
            element={<BlogDetailsRightSidebar />}
          />*/}
         
          <Route path="/contact-us" element={<Contact />} />
          <Route path="*" element={<NoMAtch />} />
         {/*  naz */}
          <Route path="/Conveyors_Apron_Produits" element={<Conveyors_Apron_Produits />} />
          <Route path="/Conveyors_IDLER_Produits" element={<Conveyors_IDLER_Produits />} />
          <Route path="/Conveyors_SLIDER_Produits" element={<Conveyors_SLIDER_Produits/>} />
          <Route path="/Conveyors_combo_belt_Produits" element={<Conveyors_combo_belt_Produits />} />
          <Route path="/Turnkey_Systems_SS" element={<Turnkey_Systems_SS />} />
          <Route path="/Turnkey_Systems_CandD" element={<Turnkey_Systems_CandD  />} />
          <Route path="/Turnkey_Systems_MSW" element={<Turnkey_Systems_MSW  />} />
          <Route path="/Turnkey_Systems_Mulch" element={<Turnkey_Systems_Mulch  />} />
          <Route path="/Conveyors_Auger_Produits" element={<Conveyors_Auger_Produits  />} />
          <Route path="/OtherOEMProducts_Trommel" element={<OtherOEMProducts_Trommel  />} />
          <Route path="/OtherOEMProducts_BUNKER" element={<OtherOEMProducts_BUNKER  />} />
          <Route path="/RagnartechProducts_Chippers" element={<RagnartechProducts_Chippers  />} />
          <Route path="/RagnartechProducts_CraneAttachments" element={<RagnartechProducts_CraneAttachments  />} />
          <Route path="/RagnartechProducts_GrindersAndShredders" element={<RagnartechProducts_GrindersAndShredders />} />
          <Route path="/services" element={<Services />} />
          {/*<Route path="/projects" element={<Projects />} />*/}
          <Route path="/MagneticEquipment" element={<MagneticEquipment />} />


          <Route path="/events" element={<BlogLeftSidebar />} />
          <Route path="/talking_about_us" element={<Talking_About_Us />} />
         
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
